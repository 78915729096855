import { createSlice } from "@reduxjs/toolkit";

import { atomicConfig } from "../../config";
import { call_api_auth } from "../../utils/services";

import { displayErrorWithTimeout } from "./errors";

const initialState = {
  advanceBusinessReportData: {},
  advanceBusinessReportLoading: false,
  advanceBusinessReportErrorMessage: null,

  sponsoredRankTrackingData: {},
  sponsoredRankTrackingDataLoading: false,
  sponsoredRankTrackingDataErrorMessage: null,

  ukSponsoredRankTrackingData: {},
  ukSponsoredRankTrackingDataLoading: false,
  ukSponsoredRankTrackingDataErrorMessage: null,

  organicRankTrackingData: {},
  organicRankTrackingDataLoading: false,
  organicRankTrackingDataErrorMessage: null,

  ukOrganicRankTrackingData: {},
  ukOrganicRankTrackingDataLoading: false,
  ukOrganicRankTrackingDataErrorMessage: null,

  skuASINsList: [],
  skuASINsLoading: false,
  skuASINsErrorMessage: null,

  productItemDetailsList: [],
  productItemDetailsLoading: false,
  productItemDetailsErrorMessage: null,

  advanceBusinessReportEvents: [],
  advanceBusinessReportEventsFlag: false,

  editSearchTermsLoading: false,
  addNewKeywordResponse: {},

  defaultAdvanceSelectionReport: {},
};

// A slice for recipes with our 3 reducers
const advanceBusinessReportSlice = createSlice({
  name: "businessReport",
  initialState,
  reducers: {
    getAdvanceBusinessReport: (state) => {
      state.advanceBusinessReportLoading = true;
      state.advanceBusinessReportErrorMessage = null;
      state.advanceBusinessReportData = {};
    },

    initAdvanceBusinessReport: (state) => {
      state.advanceBusinessReportData = {};
      state.advanceBusinessReportLoading = false;
      state.advanceBusinessReportErrorMessage = null;
    },
    getAdvanceBusinessReportFailure: (state, { payload }) => {
      state.advanceBusinessReportData = {};
      state.advanceBusinessReportLoading = false;
      state.advanceBusinessReportErrorMessage = payload;
    },
    getAdvanceBusinessReportSuccess: (state, { payload }) => {
      state.advanceBusinessReportData = payload;
      state.advanceBusinessReportLoading = false;
      state.advanceBusinessReportErrorMessage = null;
    },
    getSponsoredRankTrackingData: (state) => {
      state.sponsoredRankTrackingData = {};
      state.sponsoredRankTrackingDataLoading = true;
      state.sponsoredRankTrackingDataErrorMessage = null;
    },
    getSponsoredRankTrackingDataFailure: (state, { payload }) => {
      state.sponsoredRankTrackingData = {};
      state.sponsoredRankTrackingDataLoading = false;
      state.sponsoredRankTrackingDataErrorMessage = payload;
    },
    getSponsoredRankTrackingDataSuccess: (state, { payload }) => {
      state.sponsoredRankTrackingData = payload;
      state.sponsoredRankTrackingDataLoading = false;
      state.sponsoredRankTrackingDataErrorMessage = null;
    },
    getUkSponsoredRankTrackingData: (state) => {
      state.ukSponsoredRankTrackingData = {};
      state.ukSponsoredRankTrackingDataLoading = true;
      state.ukSponsoredRankTrackingDataErrorMessage = null;
    },
    getUkSponsoredRankTrackingDataFailure: (state, { payload }) => {
      state.ukSponsoredRankTrackingData = {};
      state.ukSponsoredRankTrackingDataLoading = false;
      state.ukSponsoredRankTrackingDataErrorMessage = payload;
    },
    getUkSponsoredRankTrackingDataSuccess: (state, { payload }) => {
      state.ukSponsoredRankTrackingData = payload;
      state.ukSponsoredRankTrackingDataLoading = false;
      state.ukSponsoredRankTrackingDataErrorMessage = null;
    },
    getOrganicRankTrackingData: (state) => {
      state.organicRankTrackingData = {};
      state.organicRankTrackingDataLoading = true;
      state.organicRankTrackingDataErrorMessage = null;
    },
    getOrganicRankTrackingDataFailure: (state, { payload }) => {
      state.organicRankTrackingData = {};
      state.organicRankTrackingDataLoading = false;
      state.organicRankTrackingDataErrorMessage = payload;
    },
    getOrganicRankTrackingDataSuccess: (state, { payload }) => {
      state.organicRankTrackingData = payload;
      state.organicRankTrackingDataLoading = false;
      state.organicRankTrackingDataErrorMessage = null;
    },
    getUkOrganicRankTrackingData: (state) => {
      state.ukOrganicRankTrackingData = {};
      state.ukOrganicRankTrackingDataLoading = true;
      state.ukOrganicRankTrackingDataErrorMessage = null;
    },
    getUkOrganicRankTrackingDataFailure: (state, { payload }) => {
      state.ukOrganicRankTrackingData = {};
      state.ukOrganicRankTrackingDataLoading = false;
      state.ukOrganicRankTrackingDataErrorMessage = payload;
    },
    getUkOrganicRankTrackingDataSuccess: (state, { payload }) => {
      state.ukOrganicRankTrackingData = payload;
      state.ukOrganicRankTrackingDataLoading = false;
      state.ukOrganicRankTrackingDataErrorMessage = null;
    },
    getSkuASINsList: (state) => {
      state.skuASINsList = [];
      state.skuASINsLoading = true;
      state.skuASINsErrorMessage = null;
    },
    getSkuASINsListSuccess: (state, { payload }) => {
      state.skuASINsList = payload;
      state.skuASINsLoading = false;
      state.skuASINsErrorMessage = null;
    },
    getSkuASINsListFailure: (state, { paylaod }) => {
      state.skuASINsList = false;
      state.skuASINsErrorMessage = paylaod;
      state.skuASINsLoading = false;
    },

    getProductItemDetails: (state) => {
      state.productItemDetailsList = [];
      state.productItemDetailsLoading = true;
      state.productItemDetailsErrorMessage = null;
    },
    getProductItemDetailsSuccess: (state, { payload }) => {
      state.productItemDetailsList = payload;
      state.productItemDetailsLoading = false;
      state.productItemDetailsErrorMessage = null;
    },
    getProductItemDetailsFailure: (state, { payload }) => {
      state.productItemDetailsList = [];
      state.productItemDetailsLoading = false;
      state.productItemDetailsErrorMessage = payload;
    },
    resetRankTracking: (state) => {
      state.rankTrackingData = {};
      state.organicRankTrackingData = {};
    },
    resetUKRankTracking: (state) => {
      state.ukRankTrackingData = {};
      state.ukOrganicRankTrackingData = {};
    },
    setDefaultAdvanceSelectionReport: (state, { payload }) => {
      state.defaultAdvanceSelectionReport = {
        ...state.defaultAdvanceSelectionReport,
        ...payload,
      };
    },
    getAdvanceBusinessReportEventsSuccess: (state, { payload }) => {
      state.advanceBusinessReportEvents = payload;
      state.advanceBusinessReportEventsErrorMessage = null;
    },
    getAdvanceBusinessReportEventsFailure: (state, { payload }) => {
      state.advanceBusinessReportEvents = [];
      state.advanceBusinessReportEventsErrorMessage = payload;
    },
    createAdvanceBusinessReportEventSuccess: (state) => {
      state.advanceBusinessReportEventsFlag =
        !state.advanceBusinessReportEventsFlag;
    },
    createAdvanceBusinessReportEventFailure: (state, { payload }) => {
      state.advanceBusinessReportEventsErrorMessage = payload;
    },
    deleteAdvanceBusinessReportEventSuccess: (state) => {
      state.advanceBusinessReportEventsFlag =
        !state.advanceBusinessReportEventsFlag;
    },
    deleteAdvanceBusinessReportEventFailure: (state, { payload }) => {
      state.advanceBusinessReportEventsErrorMessage = payload;
    },
    saveSearchTermsRelevancy: (state) => {
      state.editSearchTermsLoading = true;
    },
    saveSearchTermsRelevancySuccess: (state) => {
      state.editSearchTermsLoading = false;
    },
    saveSearchTermsRelevancyFailure: (state) => {
      state.editSearchTermsLoading = false;
    },
    addSearchTerm: (state) => {
      state.editSearchTermsLoading = true;
    },
    addSearchTermSuccess: (state) => {
      state.editSearchTermsLoading = false;
      state.addNewKeywordResponse = {
        success: true,
        message: "Keyword added successfully, check back in a few hours",
      };
    },
    addSearchTermFailure: (state, { payload }) => {
      state.editSearchTermsLoading = false;
      state.addNewKeywordResponse = {
        success: false,
        message: payload,
      };
    },
    addSearchTermClearResponse: (state) => {
      state.addNewKeywordResponse = {};
    },
  },
});

// Three actions generated from the slice
const {
  getAdvanceBusinessReport,
  initAdvanceBusinessReport,
  getAdvanceBusinessReportSuccess,
  getAdvanceBusinessReportFailure,

  getSponsoredRankTrackingData,
  getSponsoredRankTrackingDataFailure,
  getSponsoredRankTrackingDataSuccess,

  getUkSponsoredRankTrackingData,
  getUkSponsoredRankTrackingDataFailure,
  getUkSponsoredRankTrackingDataSuccess,

  getOrganicRankTrackingData,
  getOrganicRankTrackingDataFailure,
  getOrganicRankTrackingDataSuccess,

  getUkOrganicRankTrackingData,
  getUkOrganicRankTrackingDataFailure,
  getUkOrganicRankTrackingDataSuccess,

  getSkuASINsList,
  getSkuASINsListSuccess,
  getSkuASINsListFailure,

  getProductItemDetails,
  getProductItemDetailsSuccess,
  getProductItemDetailsFailure,

  resetRankTracking,
  resetUKRankTracking,
  setDefaultAdvanceSelectionReport,

  getAdvanceBusinessReportEventsSuccess,
  getAdvanceBusinessReportEventsFailure,

  createAdvanceBusinessReportEventSuccess,
  createAdvanceBusinessReportEventFailure,

  deleteAdvanceBusinessReportEventSuccess,
  deleteAdvanceBusinessReportEventFailure,

  saveSearchTermsRelevancy,
  saveSearchTermsRelevancySuccess,
  saveSearchTermsRelevancyFailure,

  addSearchTerm,
  addSearchTermSuccess,
  addSearchTermFailure,
  addSearchTermClearResponse,
} = advanceBusinessReportSlice.actions;

// A selector
// export const businessReportSelector = (state) => state.business_report;
export const advanceBusinessReportSelector = (state) =>
  state.advance_business_report;

// The reducer
export default advanceBusinessReportSlice.reducer;

// Asynchronous thunk action

export function fetchAdvanceBusinessReport(payload) {
  return async (dispatch) => {
    dispatch(getAdvanceBusinessReport(payload));

    let url;
    if (payload["compare_start_date"]) {
      url = `${atomicConfig.storeManagementServiceUrl}/advance-business-report/${payload.store}?parent_asin=${payload.asin}&sku=${payload.sku}&child_asin=${payload.child_asin}&start_date=${payload.start_date}&end_date=${payload.end_date}&periodicity=${payload.periodicity}&compare_start_date=${payload.compare_start_date}&compare_end_date=${payload.compare_end_date}`;
    } else {
      url = `${atomicConfig.storeManagementServiceUrl}/advance-business-report/${payload.store}?parent_asin=${payload.asin}&sku=${payload.sku}&child_asin=${payload.child_asin}&start_date=${payload.start_date}&end_date=${payload.end_date}&periodicity=${payload.periodicity}`;
    }
    try {
      let result = await call_api_auth(url, "GET");

      result = result.data;
      if (result.message !== undefined) {
        dispatch(getAdvanceBusinessReportFailure(result.message));
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: result.message,
          })
        );
      } else {
        dispatch(getAdvanceBusinessReportSuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(getAdvanceBusinessReportFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function resetAdvanceBusinessReport() {
  return async (dispatch) => {
    dispatch(initAdvanceBusinessReport());
  };
}

export function fetchSponsoredRankTrackingData(payload) {
  payload.marketPlace = payload.marketPlace || "us";
  return async (dispatch) => {
    let SponsoredRankTrackingData =
      payload.marketPlace === "us"
        ? getSponsoredRankTrackingData
        : getUkSponsoredRankTrackingData;

    let SponsoredRankTrackingDataFailure =
      payload.marketPlace === "us"
        ? getSponsoredRankTrackingDataFailure
        : getUkSponsoredRankTrackingDataFailure;

    let SponsoredRankTrackingDataSuccess =
      payload.marketPlace === "us"
        ? getSponsoredRankTrackingDataSuccess
        : getUkSponsoredRankTrackingDataSuccess;
    dispatch(SponsoredRankTrackingData(payload));

    let url = `${
      atomicConfig.storeManagementServiceUrl
    }/get-rank-tracking-comp?${
      payload.child_asin
        ? `child_asin=${payload.child_asin}`
        : `parent_asin=${payload.asin}`
    }&start_date=${payload.startDate}&end_date=${payload.endDate}&periodicity=${
      payload.periodicity
    }&marketplace=${payload.marketPlace}&organic=False&store=${payload.store}`;
    try {
      let result = await call_api_auth(url, "GET");
      result = result.data;

      if (result.message !== undefined) {
        dispatch(SponsoredRankTrackingDataFailure(result.message));
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: result.message,
          })
        );
      } else {
        dispatch(SponsoredRankTrackingDataSuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(SponsoredRankTrackingDataFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function fetchOrganicRankTrackingData(payload) {
  payload.marketPlace = payload.marketPlace || "us";
  return async (dispatch) => {
    let OrganicRankTrackingData =
      payload.marketPlace === "us"
        ? getOrganicRankTrackingData
        : getUkOrganicRankTrackingData;

    let OrganicRankTrackingDataFailure =
      payload.marketPlace === "us"
        ? getOrganicRankTrackingDataFailure
        : getUkOrganicRankTrackingDataFailure;

    let OrganicRankTrackingDataSuccess =
      payload.marketPlace === "us"
        ? getOrganicRankTrackingDataSuccess
        : getUkOrganicRankTrackingDataSuccess;
    dispatch(OrganicRankTrackingData(payload));

    let url = `${
      atomicConfig.storeManagementServiceUrl
    }/get-rank-tracking-comp?${
      payload.child_asin
        ? `child_asin=${payload.child_asin}`
        : `parent_asin=${payload.asin}`
    }&start_date=${payload.startDate}&end_date=${payload.endDate}&periodicity=${
      payload.periodicity
    }&marketplace=${payload.marketPlace}&organic=True&store=${payload.store}`;
    try {
      let result = await call_api_auth(url, "GET");
      result = result.data;

      if (result.message !== undefined) {
        dispatch(OrganicRankTrackingDataFailure(result.message));
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: result.message,
          })
        );
      } else {
        dispatch(OrganicRankTrackingDataSuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(OrganicRankTrackingDataFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function fetchSkuASINList(payload) {
  return async (dispatch) => {
    dispatch(getSkuASINsList());

    let url = `${atomicConfig.storeManagementServiceUrl}/fetch-sku-and-asin-list/${payload.store}`;

    try {
      let result = await call_api_auth(url, "GET");
      result = result.data;

      if (result.message !== undefined) {
        dispatch(getSkuASINsListFailure(result.message));
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: result.message,
          })
        );
      } else {
        if (payload["level"]) {
          dispatch(getSkuASINsListSuccess(result[payload["level"]]));
        } else {
          dispatch(getSkuASINsListSuccess(result));
        }
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(getSkuASINsListFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function fetchProductItemDetails(payload) {
  return async (dispatch) => {
    dispatch(getProductItemDetails());
    const { store, level } = payload;

    let url = `${atomicConfig.storeManagementServiceUrl}/fetch-product-item-details/${store}?level=${level}&marketplace=us`;

    try {
      let result = await call_api_auth(url, "GET");
      if (result?.error) {
        dispatch(getProductItemDetailsFailure(result.error));
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: result.error,
          })
        );
      } else {
        dispatch(getProductItemDetailsSuccess(result.data.product_item[0]));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(getProductItemDetailsFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function fetchAdvanceBusinessReportEvents(payload) {
  return async (dispatch) => {
    let url = `${atomicConfig.storeManagementServiceUrl}/advance-report-events?parent_asin=${payload.asin}&sku=${payload.sku}&child_asin=${payload.child_asin}&start_date=${payload.startDate}&end_date=${payload.endDate}&store=${payload.store}`;

    try {
      let result = await call_api_auth(url, "GET");
      result = result.data;
      if (result) {
        dispatch(getAdvanceBusinessReportEventsSuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(getAdvanceBusinessReportEventsFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function addAdvanceBusinessReportEvent(payload) {
  return async (dispatch) => {
    let url = `${atomicConfig.storeManagementServiceUrl}/advance-report-events`;
    let json_body = JSON.stringify(payload);

    try {
      let result = await call_api_auth(url, "POST", json_body);
      result = result.data;
      if (result) {
        dispatch(createAdvanceBusinessReportEventSuccess(payload));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(createAdvanceBusinessReportEventFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function removeAdvanceBusinessReportEvent(payload) {
  return async (dispatch) => {
    let url = `${atomicConfig.storeManagementServiceUrl}/advance-report-events`;
    let json_body = JSON.stringify(payload);

    try {
      let result = await call_api_auth(url, "DELETE", json_body);
      result = result.data;
      if (result) {
        dispatch(deleteAdvanceBusinessReportEventSuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(deleteAdvanceBusinessReportEventFailure(message));
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function resetRankTrackingData() {
  return async (dispatch) => {
    dispatch(resetRankTracking());
  };
}
export function resetUKRankTrackingData() {
  return async (dispatch) => {
    dispatch(resetUKRankTracking());
  };
}

export function changeDefaultAdvanceSelectionReport(payload) {
  return async (dispatch) => {
    dispatch(setDefaultAdvanceSelectionReport(payload));
  };
}

export function updateSearchTermsRelevancy(payload) {
  return async (dispatch) => {
    dispatch(saveSearchTermsRelevancy());

    let url = `${atomicConfig.storeManagementServiceUrl}/upsert-searchterms-relevancies?store=${payload.store}&child_asin=${payload.child_asin}`;
    let json_body = JSON.stringify(payload.rows);

    try {
      let result = await call_api_auth(url, "POST", json_body);
      result = result.data;
      if (result) {
        dispatch(saveSearchTermsRelevancySuccess(result));
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      dispatch(saveSearchTermsRelevancyFailure());
      dispatch(
        displayErrorWithTimeout({
          url: url,
          content: message,
        })
      );
    }
  };
}

export function createSearchTerm(payload) {
  return async (dispatch) => {
    dispatch(addSearchTerm());

    let url = `${atomicConfig.storeManagementServiceUrl}/add-new-search-term`;
    let json_body = JSON.stringify(payload);

    try {
      let result = await call_api_auth(url, "POST", json_body);
      result = result.data;
      if (result) {
        dispatch(addSearchTermSuccess());
      }
    } catch (error) {
      let message;
      if (error.response) {
        message = error.response.data.error;
      } else {
        message = error.message || "Something went wrong";
        dispatch(
          displayErrorWithTimeout({
            url: url,
            content: message,
          })
        );
      }
      dispatch(addSearchTermFailure(message));
    }
  };
}

export function addSearchTermClear() {
  return async (dispatch) => {
    dispatch(addSearchTermClearResponse());
  };
}
