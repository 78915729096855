import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";

const InventoryDetails = ({
  product,
  quantityfbaAvailable,
  quantityfbaReserved,
  quantityfbaInReceiving,
  quantityfbaInWorkAndShip,
  quantity3pl,
  quantityAWD,
  quantityAWDInbound,
  quantityAWDOutbound,
  shipped_qty,
  shipped_eta,
  totalLTSF,
  LTSFRecommendation,
  quantityTransfer,
  quantityProcessing,
  quantityCustomerOrders,
  marketplace,
}) => {
  const [open, setOpen] = useState(true);

  const toggleOpenStates = (newState) => {
    setOpen(newState);
  };

  return (
    <div>
      <div>Inventory:</div>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginLeft: "5px",
        }}
      >
        <div>
          {"Available: "}
          <b>{quantityfbaAvailable}</b>
          <br />
          <IconButton
            style={{
              marginLeft: "-15px",
              marginTop: "-5px",
              marginBottom: "-5px",
              display: "none",
            }}
            aria-label="expand row"
            size="small"
            onClick={() => toggleOpenStates(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          {"Reserved: "}
          <b>{quantityfbaReserved}</b>
          <br />
          <Collapse
            in={true}
            timeout="auto"
            style={{
              display: open ? "block" : "none",
              fontSize: "12px",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            <div>
              {"- FC Transfer: "}
              <b>{quantityTransfer}</b>
              <br />
              {"- FC Processing: "}
              <b>{quantityProcessing}</b>
              <br />
              {"- Customer Orders: "}
              <b>{quantityCustomerOrders}</b>
            </div>
          </Collapse>
          {"In-Receiving: "}
          <b>{quantityfbaInReceiving}</b>
          <br />
          {"In-Work+Ship: "}
          <b>{quantityfbaInWorkAndShip}</b>
          <br />
        </div>
        <div
          style={{
            marginLeft: "12px",
            visibility: marketplace === "ca" ? "hidden" : "visible",
          }}
        >
          {"3PL: "}
          <b>{quantity3pl}</b>
          <br />
          <div
            style={{ visibility: marketplace === "ca" ? "hidden" : "visible" }}
          >
            <IconButton
              style={{
                marginLeft: "-15px",
                marginTop: "-5px",
                marginBottom: "-5px",
                display: "none",
              }}
              aria-label="expand row"
              size="small"
              onClick={() => toggleOpenStates(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            {"AWD: "}
            <b>{quantityAWD}</b>
            <Collapse
              in={true}
              timeout="auto"
              style={{
                display: open ? "block" : "none",
                fontSize: "12px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <div>
                {"- AWD Inbound: "}
                <b>{quantityAWDInbound}</b>
                <br />
                {"- AWD Replenishing: "}
                <b>{quantityAWDOutbound}</b>
                <br />
              </div>
            </Collapse>
          </div>
        </div>
        <div
          style={{
            marginLeft: "20px",
            visibility: marketplace === "ca" ? "hidden" : "visible",
          }}
        >
          {"On the way: "}
          <b>{shipped_qty}</b>
          <br />
          {shipped_eta ? (
            <div
              style={{
                fontSize: "12px",
                marginLeft: "-3px",
              }}
            >
              {"- ETA: "}
              <b>
                {shipped_eta.length > 1
                  ? "Many"
                  : shipped_eta.length === 1
                  ? shipped_eta[0]
                  : ""}
              </b>
              <br />
            </div>
          ) : null}
        </div>
      </div>
      {totalLTSF.fees > 0 && (
        <Tooltip
          title={
            product.children ? (
              "Hover over child ASINs to see recommendations"
            ) : LTSFRecommendation.neededVelocityIncrease <= 0 ? (
              "Your current sales velocity is on track to avoid Long-Term Storage Fees for this ASIN. No action is needed at this time"
            ) : (
              <>
                By selling {LTSFRecommendation.neededUnitSales} more units this
                month you can save ${LTSFRecommendation.possibleSavings} of long
                term storage fees next month. You need to increase your velocity
                by {LTSFRecommendation.neededVelocityIncrease} units per day
              </>
            )
          }
        >
          <div
            style={{
              display: "flex",
              marginLeft: "5px",
              color: "red",
              maxWidth: "400px",
            }}
          >
            {totalLTSF.units} units will be charged ${totalLTSF.fees} in long
            term storage fees at the end of the month
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export { InventoryDetails };
