import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/inter";
import "@fontsource/inter/600.css";
import styled from "styled-components";

import {
  fetchComments,
  fetchProductItemDetails,
  fetchProductList,
  fetchProductPredictedUnits,
  fetchProductProfitability,
  fetchProductRanks,
  fetchProductSales,
  fetchProductUnitEconomics,
  fetchProductsLongTermStorageData,
  storeAnalyticsSelector,
} from "../../../../../redux/slices/store_analytics";
import {
  FiltersValueTextField,
  StyledSelect,
} from "../StoreProductDetails/BusinessReportAdvanced/styles";
import {
  ColumnTitle,
  EmptyProductListMsg,
  TableStyled,
} from "../StoreProducts/styles";

import { ProductRowDetailed, getTableDailyData } from "./AllProductsTableRow";
import Summaries from "./Summaries";

const CustomCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 22px;
  }
`;

const CustomInput = styled(FormControlLabel)`
  margin-left: 5px;
  & .MuiFormControlLabel-label {
    font: 300 12px "Inter", sans-serif;
  }
`;

const TableCustom = ({ style, value }) => {
  // const [lastFetchedProduct, setLastFetchedProduct] = useState(null);

  var columns = [
    "All store Products in the last 30 days",
    // "Unit economics report",
    // "FBA Quantity",
    // "3PL Quantity",
    // "Units on the way",
  ];

  const dispatch = useDispatch();

  const {
    productItemDetailsList,
    productItemDetailsLoading,
    productListData,
    productListLoading,
    productSalesList,
    productSalesLoading,
    productUnitEconomicsList,
    productUnitEconomicsLoading,
    productProfitabilityList,
    productProfitabilityLoading,
    productRanksList,
    productRanksLoading,
    productLongTermStorageData,
    productLongTermStorageDataLoading,
    productPredictedUnitsList,
    productPredictedUnitsLoading,
    productCommentsData,
  } = useSelector(storeAnalyticsSelector);

  const filterAttributes = [
    "Days of supply",
    "Unit economics",
    "Realistic stock",
    "Product velocity",
    "Velocity change",
  ];
  const filterActions = ["greater than", "less than", "equal to"];

  const [currStore, setCurrStore] = useState([]);
  const [currMarketplace, setCurrMarketplace] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [openAllRows, setOpenAllRows] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const filterAttributeRef = useRef(null);
  const filterActionRef = useRef(null);
  const filterValueRef = useRef(null);
  const [filterFieldsResetFlag, setFilterFieldsResetFlag] = useState(false);
  const [filterErrors, setFilterErrors] = useState({
    filterAttribute: false,
    filterAction: false,
    filterValue: false,
  });

  const [progress, setProgress] = useState(0);
  const totalApiCalls = 8; // Total number of API calls

  useEffect(() => {
    const updateProgress = () => {
      const completedApiCalls = [
        !productItemDetailsLoading,
        !productListLoading,
        !productSalesLoading,
        !productUnitEconomicsLoading,
        !productProfitabilityLoading,
        !productLongTermStorageDataLoading,
        !productRanksLoading,
        !productPredictedUnitsLoading,
      ].filter(Boolean).length;

      setProgress((completedApiCalls / totalApiCalls) * 100);
    };

    updateProgress();
  }, [
    productItemDetailsLoading,
    productListLoading,
    productSalesLoading,
    productUnitEconomicsLoading,
    productProfitabilityLoading,
    productLongTermStorageDataLoading,
    productRanksLoading,
    productPredictedUnitsLoading,
  ]);

  useEffect(() => {
    if (
      value.store === "all" ||
      (value.store === currStore && value.marketplace === currMarketplace)
    ) {
      return;
    }

    const fetchData = async () => {
      dispatch(
        fetchProductList({
          store: value.store,
          marketplace: value.marketplace,
          duration: value.compareConditions.current,
          hierarchy: "hierarchy",
        })
      );

      dispatch(
        fetchProductItemDetails({
          store: value.store,
          marketplace: value.marketplace,
          level: "hirearchy",
        })
      );

      dispatch(
        fetchProductSales({
          store: value.store,
          marketplace: value.marketplace,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );

      dispatch(
        fetchProductRanks({
          store: value.store,
          marketplace: value.marketplace,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );

      dispatch(
        fetchProductUnitEconomics({
          store: value.store,
          marketplace: value.marketplace,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );

      dispatch(
        fetchProductProfitability({
          store: value.store,
          marketplace: value.marketplace,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );

      dispatch(
        fetchProductsLongTermStorageData({
          store: value.store,
          marketplace: value.marketplace,
        })
      );

      dispatch(
        fetchComments({
          store: value.store,
          marketplace: value.marketplace,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );

      if (new Date().getMonth() >= 10 && new Date().getMonth() <= 12) {
        dispatch(
          fetchProductPredictedUnits({
            store: value.store,
            marketplace: value.marketplace,
            start_date: value.compareConditions.current.start_date,
            end_date: value.compareConditions.current.end_date,
          })
        );
      }
    };

    fetchData();
    setCurrStore(value.store);
    setCurrMarketplace(value.marketplace);
  }, [
    dispatch,
    currStore,
    currMarketplace,
    value.store,
    value.marketplace,
    value.compareConditions,
  ]); // table rerender on compare conditions change

  useEffect(() => {
    if (Object.keys(productItemDetailsList).length > 0) {
      setProductsList(productItemDetailsList);
    } else {
      setProductsList([]);
    }
  }, [productItemDetailsList]);

  const relevantProductData = (product) => {
    if (!product.children) {
      return (
        productListData &&
        product.sku.split(",").map((sku) => {
          return { sku: sku.trim(), ...productListData[sku.trim()] };
        })
      );
    } else {
      return (
        productListData &&
        Object.entries(productListData)
          .map(([key, value]) => {
            if (
              product.children.map((c) => c.child_asin).includes(value.asin)
            ) {
              return { ...value, sku: key };
            } else {
              return null;
            }
          })
          .filter(Boolean)
      );
    }
  };

  const relevantCommentsData = (product) => {
    let emptyCommentsData = {
      [product.child_asin]: [],
    };
    if (!productCommentsData) {
      return emptyCommentsData;
    }
    let parentChildAsins = [];
    if (product.children) {
      parentChildAsins = product.children.map((c) => c.child_asin);
      parentChildAsins.push(product.parent_asin);
    } else {
      parentChildAsins.push(product.child_asin);
    }

    return Object.fromEntries(
      Object.entries(productCommentsData).filter(([key]) =>
        parentChildAsins.includes(key)
      )
    );
  };

  const relevantPredictedUnitsData = (product) => {
    let emptyPredictedUnitsData = {
      [product.child_asin]: {},
      dates: [],
    };
    if (!productPredictedUnitsList) {
      return emptyPredictedUnitsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productPredictedUnitsList)
      .filter(([key]) => childAsins.includes(key) || key === "dates")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyPredictedUnitsData);
  };

  const relevantRanksData = (product) => {
    let emptyRanksData = {
      [product.child_asin]: {},
    };
    if (!productRanksList) {
      return emptyRanksData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productRanksList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyRanksData);
  };

  const relevantProfitabilityData = (product) => {
    let emptyProfitabilityData = {
      [product.child_asin]: {},
    };
    if (!productProfitabilityList) {
      return emptyProfitabilityData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productProfitabilityList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyProfitabilityData);
  };

  const relevantSalesData = (product) => {
    let emptySalesData = {
      [product.child_asin]: { dates: [], units: [], avg_price: [], sales: [] },
    };
    if (!productSalesList) {
      return emptySalesData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productSalesList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptySalesData);
  };

  const relevantEconomicsData = (product) => {
    let emptyEconomicsData = {
      [product.child_asin]: {
        date: "",
        price: 0,
        cogs: 0,
        fulfillment_fee: 0,
        referral_fee: 0,
      },
    };
    if (!productUnitEconomicsList) {
      return emptyEconomicsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productUnitEconomicsList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyEconomicsData);
  };

  const relevantLongTermStorageData = (product) => {
    let emptyLongTermStorageData = {};
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    if (!productLongTermStorageData) {
      return emptyLongTermStorageData;
    }

    return Object.entries(productLongTermStorageData)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyLongTermStorageData);
  };

  const isDataLoading =
    productItemDetailsLoading ||
    productListLoading ||
    productSalesLoading ||
    productUnitEconomicsLoading ||
    productProfitabilityLoading ||
    productLongTermStorageDataLoading ||
    productRanksLoading ||
    productPredictedUnitsLoading;

  const resetFilterFields = () => {
    filterAttributeRef.current.value = "";
    filterActionRef.current.value = "";
    filterValueRef.current.value = "";
    setFilterFieldsResetFlag(!filterFieldsResetFlag);
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  const addFilter = () => {
    const filterFieldsErrors = {
      filterAttribute: !filterAttributeRef.current.value,
      filterAction: !filterActionRef.current.value,
      filterValue: !filterValueRef.current.value,
    };
    if (
      filterFieldsErrors.filterAttribute ||
      filterFieldsErrors.filterAction ||
      filterFieldsErrors.filterValue
    ) {
      setFilterErrors(filterFieldsErrors);
      return;
    }

    setOpenAllRows(true);
    const newFilter = {
      attr: filterAttributeRef.current.value,
      action: filterActionRef.current.value,
      value: parseFloat(filterValueRef.current.value),
    };
    setAppliedFilters([...appliedFilters, newFilter]);
    resetFilterFields();
  };

  const removeFilter = (indexToRemove) => {
    setAppliedFilters(
      appliedFilters.filter((_, index) => index !== indexToRemove)
    );
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  const getChildDailyData = (productChild, parentAsin) => {
    let salesData = relevantSalesData(productChild);
    let profitabilityData = relevantProfitabilityData(productChild);
    let ranksData = relevantRanksData(productChild);
    let predictedUnitsData = relevantPredictedUnitsData(productChild);
    let leaf = true;

    let dailyData = getTableDailyData(
      salesData,
      profitabilityData,
      ranksData,
      predictedUnitsData,
      [],
      leaf,
      currStore,
      value.marketplace
    );

    return dailyData.map((data) => {
      const newData = Object.fromEntries(
        Object.entries(data)
          .filter(([key]) => key !== "id")
          .map(([key, value]) => [
            key,
            value && value.value !== undefined ? value.value : value,
          ])
      );
      return {
        ...newData,
        parent_asin: parentAsin,
        asin: productChild.child_asin,
      };
    });
  };

  const getReportChildAsinTableData = (
    productsList,
    productItemDetailsList
  ) => {
    return productsList.flatMap((product) => {
      const parentAsin =
        productItemDetailsList.find((item) =>
          item.children?.some(
            (child) => child.child_asin === product.child_asin
          )
        )?.parent_asin || "N/A"; // Fallback value if parent_asin is not found

      return product.children
        ? product.children.flatMap((productChild) =>
            getChildDailyData(productChild, parentAsin)
          )
        : getChildDailyData(product, parentAsin);
    });
  };

  const getReportChildAsinTableHeaders = (productSalesList) => {
    return [
      "parent_asin",
      "asin",
      "field",
      "total",
      ...Object.entries(productSalesList)[0][1]["dates"],
    ];
  };

  const getReportUnitEconomicsData = (
    productUnitEconomicsList,
    productItemDetailsList
  ) => {
    let data = Object.entries(productUnitEconomicsList).map(([asin, data]) => {
      const parentAsin =
        productItemDetailsList.find((item) =>
          item.children?.some((child) => child.child_asin === asin)
        )?.parent_asin || "N/A"; // Fallback value if parent_asin is not found

      return {
        parent_asin: parentAsin,
        asin,
        ...data,
      };
    });
    return data;
  };

  const getReportUnitEconomicsHeaders = () => {
    const headers = Object.keys(
      Object.values(productUnitEconomicsList)?.[0] || {}
    );
    return ["parent_asin", "asin", ...headers];
  };

  return (
    <React.Fragment>
      <Summaries
        productSalesList={productSalesList}
        productProfitabilityList={productProfitabilityList}
        isDataLoading={isDataLoading}
        marketplace={value.marketplace}
      />
      {/* table filters */}
      <div style={{ "margin-bottom": "10px" }}>
        <CustomInput
          control={
            <CustomCheckbox
              checked={openAllRows}
              onChange={(e) => setOpenAllRows(e.target.checked)}
            />
          }
          label={"Expand all products"}
        />
        <FormControl error={filterErrors.filterAttribute}>
          <StyledSelect
            ref={filterAttributeRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Attribue";
              }
              return selected;
            }}
            inputProps={{ ref: filterAttributeRef }}
            key={`attribute-${filterFieldsResetFlag}`}
            error={filterErrors.filterAttribute}
          >
            {filterAttributes.map((attr, index) => (
              <MenuItem key={index} value={attr}>
                {attr}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAttribute && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterAction}>
          <StyledSelect
            ref={filterActionRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Action";
              }
              return selected;
            }}
            inputProps={{ ref: filterActionRef }}
            key={`action-${filterFieldsResetFlag}`}
            error={filterErrors.filterAction}
          >
            {filterActions.map((action, index) => (
              <MenuItem key={index} value={action}>
                {action}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAction && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterValue} style={{ width: "10%" }}>
          <FiltersValueTextField
            label="Enter Value"
            variant="outlined"
            size="small"
            disabled={isDataLoading || currStore.length === 0}
            inputProps={{ ref: filterValueRef }}
            key={`value-${filterFieldsResetFlag}`}
            error={filterErrors.filterValue}
          />
          {filterErrors.filterValue && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <Button
          style={{ "margin-left": "10px" }}
          onClick={addFilter}
          disabled={isDataLoading || currStore.length === 0}
        >
          Add filter
        </Button>
      </div>
      {appliedFilters.length > 0 && (
        <div style={{ "margin-left": "16px", "margin-top": "10px" }}>
          Currently applied filters:
          <ul>
            {appliedFilters.map((filter, index) => {
              return (
                <li key={index}>
                  <div>
                    {filter.attr} {filter.action} {filter.value}
                    <Button onClick={() => removeFilter(index)}>X</Button>
                  </div>
                  {appliedFilters.length > 1 &&
                    index !== appliedFilters.length - 1 &&
                    "and"}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {progress < 100 && value.store !== "all" && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            {`Loading: ${Math.round(progress)}%`}
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      {/* list of all products in a table */}
      <TableStyled style={style} component={Paper}>
        {isDataLoading ? (
          <>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton
                key={item}
                width={"97%"}
                sx={{ mt: 5, ml: 5 }}
                variant="rectangular"
                height={15}
              />
            ))}
          </>
        ) : (
          <Table>
            {/* table headers */}
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                }}
              >
                {columns.map((e, i) => (
                  <TableCell
                    key={i}
                    style={{
                      padding: "13px",
                      whiteSpace: "nowrap",
                      borderLeft:
                        e === "Conversion Rate" && "1px solid #e0e0e0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "#64748B",
                        position: "relative",
                      }}
                      data-testid="table_header"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ColumnTitle>{e}</ColumnTitle>
                      </div>
                    </div>
                  </TableCell>
                ))}
                {productsList.length !== 0 ? (
                  <>
                    <TableCell
                      style={{
                        display: "flex",
                        color: "#64748B",
                        justifyContent: "flex-end",
                        flexGrow: 1,
                      }}
                    >
                      <CSVLink
                        data={getReportChildAsinTableData(
                          productsList,
                          productItemDetailsList
                        )}
                        filename={value.store + "_table_data_report.csv"}
                        headers={getReportChildAsinTableHeaders(
                          productSalesList
                        )}
                        disabled={isDataLoading}
                      >
                        <DownloadIcon
                          style={{ marginRight: "5px" }}
                          disabled={isDataLoading}
                        />
                      </CSVLink>
                      {"ASIN table data report"}
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        color: "#64748B",
                        justifyContent: "flex-end",
                        flexGrow: 0,
                      }}
                    >
                      <CSVLink
                        data={getReportUnitEconomicsData(
                          productUnitEconomicsList,
                          productItemDetailsList
                        )}
                        filename={value.store + "_unit_economics_report.csv"}
                        headers={getReportUnitEconomicsHeaders(
                          productUnitEconomicsList
                        )}
                        disabled={isDataLoading}
                      >
                        <DownloadIcon
                          style={{ marginRight: "5px" }}
                          disabled={isDataLoading}
                        />
                      </CSVLink>
                      {"Unit Economics report"}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            {/* table body products - productListData */}
            {productsList.length !== 0 ? (
              <TableBody>
                {productsList.map((product, index) => (
                  <ProductRowDetailed
                    key={index}
                    store={value.store}
                    sku={product.sku}
                    product={product}
                    compareConditions={value.compareConditions}
                    leaf={value.periodicity}
                    openAllRows={openAllRows}
                    inventoryData={relevantProductData(product)}
                    salesData={relevantSalesData(product)}
                    unitEconomicsData={relevantEconomicsData(product)}
                    profitabilityData={relevantProfitabilityData(product)}
                    ranksData={relevantRanksData(product)}
                    longTermStorageData={relevantLongTermStorageData(product)}
                    predictedUnitsData={relevantPredictedUnitsData(product)}
                    comments={relevantCommentsData(product)}
                    filters={appliedFilters}
                    setParentShow={null}
                    parentApiRef={null}
                    parentScrollHandler={null}
                    marketplace={value.marketplace}
                  />
                ))}
              </TableBody>
            ) : null}
          </Table>
        )}
        {!productItemDetailsLoading && productItemDetailsList.length === 0 && (
          <EmptyProductListMsg>
            {value === "all"
              ? "Please select a store first!"
              : "No products available"}
          </EmptyProductListMsg>
        )}
      </TableStyled>
    </React.Fragment>
  );
};

export default TableCustom;
