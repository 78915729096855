import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { setMarketplace } from "../../../../../redux/slices/store_analytics";

export default function MarketPicker({
  value,
  onMarketSelected,
  style = {},
  disabled = false,
}) {
  const dispatch = useDispatch();
  const markets = [
    { label: "🌏", value: "all" },
    { label: "🇺🇸", value: "us" },
    { label: "🇨🇦", value: "ca" },
  ];
  const useStyles = makeStyles({
    selectMarketStyle: {
      borderRadius: "10px",
      height: "40px",
      fontWeight: "bold",
      margin: "4px",
      "& fieldset": {
        borderWidth: "2px",
      },
    },
    customStyle: style,
    selectedMenuItem: {
      backgroundColor: "#ffffff !important",
    },
  });

  const classes = useStyles();

  const handleMarketChange = (event) => {
    let name = markets.filter((x) => x.value === event.target.value)[0].value;
    onMarketSelected(name);
    dispatch(setMarketplace(name));
  };

  return (
    <Select
      className={`${classes.selectMarketStyle} ${classes.customStyle}`}
      labelId="market-to-select-label"
      id="market-to-select"
      value={value}
      onChange={handleMarketChange}
      disabled={disabled}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "rgba(55,111,208,0.08) !important",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "rgba(0,0,0,0.04) !important",
            },
          },
        },
      }}
    >
      <MenuItem selected={true} disabled={true} key="all" value="all">
        Select Marketplace
      </MenuItem>
      {markets.map((option, index) => (
        <MenuItem
          key={`${option.value} + ${index}`}
          value={option.value}
          className={classes.selectedMenuItem}
          disabled={option.value === "all"}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
